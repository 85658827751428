// src/components/ActionButton/ActionButton.jsx
import React from 'react';
import './ActionButton.styles.scss';

const ActionButton = ({ href, image, imageAlt, title }) => {
  return (
    <a href={href} className="action-button">
      <div className="action-button__circle">
        <img 
          src={image} 
          alt={imageAlt}
          className="action-button__image"
        />
      </div>
      <span className="action-button__label">{title}</span>
    </a>
  );
};
export default ActionButton;

