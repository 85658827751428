import React from 'react';
import './About.styles.scss';

const About = () => {
  return (
    <section className="about" id="about">
      <div className="about__container">
        <h2>So, what the heck is pIPe?</h2>
        <div className="about__content">
          <div>
            <p>
              Pipecoin ("pIPe") is a digital token conforming to the ERC-20 token
              standard. It lacks functionality, utility, and intrinsic value and
              does not promise or imply any financial returns, profits, interest,
              or dividends.
            </p>
          </div>
          <div>
          <p>
          Where intellectual property meets innovation! 🚀💥 Add pIPe to your wallet by connecting to the{" "}
            <a 
              href="https://docs.story.foundation/docs/odyssey-wallet-setup#/"
              className="text-green-400 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
              >
          Story network
        </a>
  {" "}and importing the pIPe contract: 0x5d26AED76E8a5ddb7Fdb4c9d2F973ED62D2381BC Join the pIPe movement today!
</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;