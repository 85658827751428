import React from "react";
import "./RoadmapPhase.styles.scss";

const RoadmapPhase = ({ title, items, image, imageAlt }) => {

  return (
    <div className="roadmap-phase">
      <div className="roadmap-phase__content">
        <h3>{title}</h3>
        <ul>
          {items.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>
      <div className="roadmap-phase__image">
        <img src={image} alt={imageAlt} />
      </div>
    </div>
  );
};

export default RoadmapPhase;
