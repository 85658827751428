import React from 'react';
import RoadmapPhase from './RoadmapPhase';
import './Roadmap.styles.scss';

const Roadmap = () => {
  const phases = [
    {
      title: 'Phase 1: The genesis',
      items: ['Presale', 'Community Building', 'Token Launch'],
      image: '/images/phase1.jpeg',
      imageAlt: 'Phase 1 Development'
    },
    {
      title: 'Phase 2: Expansion',
      items: [
        'Exchange Listings',
        'Partnership Development',
        'The Bridge: Buy and sell easy and low cost'
      ],
      image: '/images/phase2.jpeg',
      imageAlt: 'Phase 2 Expansion'
    },
    {
      title: 'Phase 3: Degenerate',
      items: [
        'DAO Creation: Control the future',
        'Definitition of the Game',
        'Ecosystem Growth'
      ],
      image: '/images/phase3.png',
      imageAlt: 'Phase 3 THE GAME'
    }
  ];

  return (
    <section className="roadmap" id="roadmap">
      <div className="roadmap__container">
        <h2>Roadmap</h2>
        <div className="roadmap__phases">
          {phases.map((phase, index) => (
            <RoadmapPhase key={index} {...phase} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Roadmap;