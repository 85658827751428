import React from 'react';
import { Twitter, MessageCircle, Send } from 'lucide-react';
import './Footer.styles.scss';

const Footer = () => {
  const socialLinks = [
    {
      href: 'https://x.com/therealpipecoin',
      Icon: Twitter,
      className: 'footer__icon--twitter'
    },
    {
      href: 'https://t.me/pipedegen',
      Icon: Send,
      className: 'footer__icon--telegram'
    }
  ];

  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__social">
          {socialLinks.map(({ href, Icon, className }) => (
            <a
              key={href}
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              className={`footer__icon ${className}`}
            >
              <Icon size={32} strokeWidth={1.5} />
            </a>
          ))}
        </div>
        
        <div className="footer__copyright">
          <p>&copy; 2024 pIPe Coin. All rights unreserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
