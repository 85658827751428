import React, { useState } from 'react';
import { Menu, X } from 'lucide-react';
import './Header.styles.scss';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <nav className="header__nav">
        <div className="header__logo">
          <img src="/images/logo.png" alt="pIPe Logo" className="header__logo-img" />
          <div className="header__logo-text">
            <span>pIPe Coin</span>
          </div>
        </div>

        {/* Mobile Menu Button */}
        <button className="header__menu-button" onClick={toggleMenu}>
          {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>

        {/* Navigation Links */}
        <div className={`header__links ${isMenuOpen ? 'header__links--open' : ''}`}>
          <a href="#about">About</a>
          <a href="#roadmap">Roadmap</a>
          <a href="#tokenomics">Tokenomics</a>
        </div>
      </nav>
    </header>
  );
};

export default Header;