import React from 'react';
import Header from './components/header/Header';
import Hero from './components/hero/Hero';
import About from './components/about/About';
import Roadmap from './components/roadmap/Roadmap';
import Tokenomics from './components/tokenomics/Tokenomics';
import Footer from './components/footer/Footer';
import './App.styles.scss';

const App = () => {
  return (
    <div className="app">
      <Header />
      <main className="app__main">
        <Hero />
        <About />
        <Roadmap />
        <Tokenomics />
      </main>
      <Footer />
    </div>
  );
};

export default App;
